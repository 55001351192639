<template>
  <b-button
    size="sm"
    :disabled="loading"
    @click="pay()"
  >
    <b-spinner
      v-if="loading"
      small
    /> Pay
  </b-button>
</template>

<script>
import { getRequestErrorText } from '@/utils'
import ApiService from '@/ApiService'

export default {
  name: 'PayButton',
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    pay () {
      this.loading = true

      ApiService.post(`/balance/pay?userId=${this.userId}`)
        .then(() => {
          this.$emit('payed')
        })
        .catch(error => {
          this.$bvToast.toast(getRequestErrorText(error), {
            title: 'Pay error',
            variant: 'danger'
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
