<template>
  <div>
    <b-table
      :items="itemsProvider"
      :fields="fields"
      :api-url="`/balance?userId=${userId}`"
      :current-page="currentPage"
      :per-page="perPage"
      stacked="sm"
      show-empty
      small
    />
    <table-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
    />
  </div>
</template>

<script>
import table from '@/mixins/table'
import TablePagination from '@/components/TablePagination'

export default {
  name: 'UserBalanceDetailsTable',
  components: { TablePagination },
  mixins: [table],
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      fields: [
        { key: 'alias' },
        { key: 'operation' },
        { key: 'amount' },
        { key: 'before' },
        { key: 'after' }
      ]
    }
  }
}
</script>
