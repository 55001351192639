<template>
  <div>
    <div class="row">
      <div class="col-5 col-md-3 col-lg-2">
        <page-title />
      </div>
      <div class="col-2 col-md-2 col-lg-2 mt-2 mb-2">
        <b-button
          v-b-modal.fix
          :disabled="!selected.length"
        >
          <b-icon icon="pencil" />
          Fix
        </b-button>
      </div>
      <div class="col-2 col-md-2 col-lg-2 mt-2 mb-2">
        <span v-if="totalBalance">Total: {{ totalBalance }}</span>
      </div>
      <div class="col-5 col-md-3 col-lg-2 mt-2 offset-md-1">
        <h3>{{ cardsCount }} / {{ presenceThreshold }}</h3>
      </div>
    </div>
    <b-table
      ref="table"
      :items="balancesProvider"
      :fields="fields"
      api-url="/balance/list"
      stacked="sm"
      show-empty
      selectable
      @row-selected="onRowSelected"
    >
      <template #cell(selected)="{ rowSelected, index }">
        <b-form-checkbox
          :checked="rowSelected"
          @change="$event ? selectRow(index) : unselectRow(index)"
        />
      </template>
      <template #head(selected)>
        <b-form
          class="mass-actions-form"
          inline
        >
          <b-form-checkbox
            v-model="selectAll"
            button-variant="outline-secondary"
            @change="$event ? selectAllRows() : clearSelected()"
          />
        </b-form>
      </template>

      <template #cell(votes)="{ item }">
        {{ calcVotes(item) }}
      </template>

      <template #cell(actions)="{ item, toggleDetails, detailsShowing }">
        <pay-button
          v-if="item.balance > 0"
          class="mr-2"
          :user-id="item.userId"
          @payed="refresh"
        >
          Pay
        </pay-button>
        <b-button
          size="sm"
          variant="light"
          @click="toggleDetails"
        >
          {{ detailsShowing ? 'Hide details' : 'Details' }}
        </b-button>
      </template>

      <template #row-details="{ item }">
        <b-card>
          <user-balance-details-table :user-id="item.userId" />
        </b-card>
      </template>
    </b-table>
    <b-modal
      id="fix"
      title="Fix balance"
      :ok-disabled="!canSubmitFix"
      @ok="submitFix"
    >
      <b-form @submit.prevent="fix()">
        <b-row>
          <b-col class="col-4 mt-2 offset-1">
            <b-form-radio-group
              id="fix-sign"
              v-model="fixSign"
              name="fix-sign"
            >
              <b-form-radio value="+">
                +
              </b-form-radio>
              <b-form-radio value="-">
                -
              </b-form-radio>
            </b-form-radio-group>
          </b-col>
          <b-col class="col-2 mt-2 mb-0">
            <p>Cents</p>
          </b-col>
          <b-col class="col-5">
            <b-form-input
              id="fix-amount"
              v-model="fixAmount"
              type="number"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-ok>
        <b-spinner
          v-if="fixInProgress"
          small
        />
        Save
      </template>
    </b-modal>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle'
import table from '@/mixins/table'
import PayButton from '@/components/PayButton'
import UserBalanceDetailsTable from '@/components/UserBalanceDetailsTable'
import ApiService from '../../ApiService'
import { getRequestErrorText } from '@/utils'

export default {
  name: 'Balance',
  components: {
    UserBalanceDetailsTable,
    PayButton,
    PageTitle
  },
  mixins: [table],
  data () {
    return {
      fields: [
        {
          key: 'selected',
          label: ''
        },
        {
          key: 'login',
          label: 'User'
        },
        { key: 'balance' },
        {
          key: 'votes',
          label: 'Votes (correct/all)'
        },
        { key: 'actions' }
      ],
      currentPage: null,
      perPage: null,
      presenceThreshold: null,
      cardsCount: null,
      selected: [],
      selectAll: false,
      fixInProgress: false,
      fixAmount: null,
      fixSign: null,
      totalBalance: null
    }
  },
  computed: {
    canSubmitFix () {
      return this.fixAmount > 0 && this.fixSign
    }
  },
  watch: {
    selected () {
      this.selectAll = this.selected.length === this.rows.length
    }
  },
  created () {
    this.$root.$on('PresenceRecalculated', this.handlePresenceRecalculated)
    this.$root.$on('CardClosed', this.handleCorrectAnswersRecalculated)
  },
  destroyed () {
    this.$root.$off(['PresenceRecalculated', 'CardClosed'])
  },
  methods: {
    balancesProvider (ctx, callback) {
      let items = []

      ApiService.get(ctx.apiUrl)
        .then(response => {
          items = response.data.balances
          this.cardsCount = response.data.cardsCount
          this.presenceThreshold = response.data.presenceThreshold
          this.countTotalBalance(items)
          callback(items)
        })
        .catch(error => {
          console.log(error)
          this.$bvToast.toast(getRequestErrorText(error), {
            title: 'Error while loading balances',
            variant: 'danger'
          })

          callback(items)
        })

      return null
    },
    countTotalBalance (items) {
      this.totalBalance = items.reduce((a, v) => {
        return a + parseFloat(v.balance)
      }, 0).toFixed(2)
    },
    handlePresenceRecalculated (payload) {
      this.cardsCount = payload.numClosedCards
      this.presenceThreshold = payload.cardsThreshold
      this.$refs.table.localItems.map(e => {
        if (e.userId === payload.voterId) {
          e.totalVotes = payload.votesCount
        }
      })
    },
    handleCorrectAnswersRecalculated (payload) {
      this.cardsCount = payload.numClosedCards
      this.$refs.table.localItems.map(e => {
        if (e.userId in payload.votes) {
          e.correctVotes = payload.votes[e.userId]
        }
      })
    },
    calcVotes (item) {
      const correctStats = item.totalVotes > 0 ? Math.round(item.correctVotes / item.totalVotes * 100) : 0
      const totalStats = this.cardsCount > 0 ? Math.round(item.totalVotes / this.presenceThreshold * 100) : 0
      return `${item.correctVotes} (${correctStats}%) / ${item.totalVotes} (${totalStats}%)`
    },
    onRowSelected (items) {
      this.selected = items
    },
    submitFix (event) {
      event.preventDefault()
      this.fix()
    },
    fix () {
      this.fixInProgress = true

      ApiService.post('/balance/fix', {
        sign: this.fixSign,
        amnt: this.fixAmount,
        ids: this.selected.map(e => e.userId)
      })
        .then(_ => {
          this.$bvToast.toast('Successfully updated for ' + this.selected.map(e => e.login).join(), {
            title: 'Balances have been updated',
            variant: 'success'
          })
          this.refresh()
        })
        .catch(error => {
          this.$bvToast.toast(getRequestErrorText(error), {
            title: 'Manual balance fix error',
            variant: 'danger'
          })
        })
        .finally(() => {
          this.$bvModal.hide('fix')
          this.fixInProgress = false
          this.fixAmount = null
          this.fixSign = null
          this.clearSelected()
        })
    }
  }
}
</script>
