var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-5 col-md-3 col-lg-2"},[_c('page-title')],1),_c('div',{staticClass:"col-2 col-md-2 col-lg-2 mt-2 mb-2"},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.fix",modifiers:{"fix":true}}],attrs:{"disabled":!_vm.selected.length}},[_c('b-icon',{attrs:{"icon":"pencil"}}),_vm._v(" Fix ")],1)],1),_c('div',{staticClass:"col-2 col-md-2 col-lg-2 mt-2 mb-2"},[(_vm.totalBalance)?_c('span',[_vm._v("Total: "+_vm._s(_vm.totalBalance))]):_vm._e()]),_c('div',{staticClass:"col-5 col-md-3 col-lg-2 mt-2 offset-md-1"},[_c('h3',[_vm._v(_vm._s(_vm.cardsCount)+" / "+_vm._s(_vm.presenceThreshold))])])]),_c('b-table',{ref:"table",attrs:{"items":_vm.balancesProvider,"fields":_vm.fields,"api-url":"/balance/list","stacked":"sm","show-empty":"","selectable":""},on:{"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"cell(selected)",fn:function(ref){
var rowSelected = ref.rowSelected;
var index = ref.index;
return [_c('b-form-checkbox',{attrs:{"checked":rowSelected},on:{"change":function($event){$event ? _vm.selectRow(index) : _vm.unselectRow(index)}}})]}},{key:"head(selected)",fn:function(){return [_c('b-form',{staticClass:"mass-actions-form",attrs:{"inline":""}},[_c('b-form-checkbox',{attrs:{"button-variant":"outline-secondary"},on:{"change":function($event){$event ? _vm.selectAllRows() : _vm.clearSelected()}},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}})],1)]},proxy:true},{key:"cell(votes)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.calcVotes(item))+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var toggleDetails = ref.toggleDetails;
var detailsShowing = ref.detailsShowing;
return [(item.balance > 0)?_c('pay-button',{staticClass:"mr-2",attrs:{"user-id":item.userId},on:{"payed":_vm.refresh}},[_vm._v(" Pay ")]):_vm._e(),_c('b-button',{attrs:{"size":"sm","variant":"light"},on:{"click":toggleDetails}},[_vm._v(" "+_vm._s(detailsShowing ? 'Hide details' : 'Details')+" ")])]}},{key:"row-details",fn:function(ref){
var item = ref.item;
return [_c('b-card',[_c('user-balance-details-table',{attrs:{"user-id":item.userId}})],1)]}}])}),_c('b-modal',{attrs:{"id":"fix","title":"Fix balance","ok-disabled":!_vm.canSubmitFix},on:{"ok":_vm.submitFix},scopedSlots:_vm._u([{key:"modal-ok",fn:function(){return [(_vm.fixInProgress)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Save ")]},proxy:true}])},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.fix()}}},[_c('b-row',[_c('b-col',{staticClass:"col-4 mt-2 offset-1"},[_c('b-form-radio-group',{attrs:{"id":"fix-sign","name":"fix-sign"},model:{value:(_vm.fixSign),callback:function ($$v) {_vm.fixSign=$$v},expression:"fixSign"}},[_c('b-form-radio',{attrs:{"value":"+"}},[_vm._v(" + ")]),_c('b-form-radio',{attrs:{"value":"-"}},[_vm._v(" - ")])],1)],1),_c('b-col',{staticClass:"col-2 mt-2 mb-0"},[_c('p',[_vm._v("Cents")])]),_c('b-col',{staticClass:"col-5"},[_c('b-form-input',{attrs:{"id":"fix-amount","type":"number"},model:{value:(_vm.fixAmount),callback:function ($$v) {_vm.fixAmount=$$v},expression:"fixAmount"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }